/* eslint-disable */

import Vue from 'vue';
import Vuex from 'vuex';

import Products from '../products';
import neededProducts from './neededProducts';
import i18n from '@/languages/i18n';

Vue.use(Vuex);

// const language = localStorage.getItem('language') || 'sv-SE';
const language = 'sv-SE'; // Always use swedish until language selector is implemented again
i18n.locale = language;

const store = new Vuex.Store({
  modules: {
    neededProducts,
  },
  state: {
    fieldValidationErrors: [],
    steps: [
      'chooseRoof',
      'chooseRoofTile',
      // 'chooseRoofTileTreatment',
      // 'chooseRoofTileColor',
      // 'snowprotection',
      // 'roofBridge',
      // 'roofRail',
      // 'roofHatchSafety',
      'safeProducts',
      'alternatives',
      'summaryStep',
    ],
    products: Products,
    showProductBreadcrumb: true,
    activeStep: 0,
    validSteps: [],
    roofTileObj: null,

    selections: {
      rooftype: { value: null, default: null, name: i18n.t('summary.roof_type') },
      rooftile: { value: null, default: null, name: i18n.t('summary.roof_tile') },
      rooftileTreatment: { value: null, default: null, name: i18n.t('summary.tile_treatment') },
      rooftileColor: { value: null, default: null, name: i18n.t('summary.tile_color') },

      roofSafetyLengths: { value: [], default: [], name: i18n.t('summary.roof_safety_lengths') },
      roofBridgeLengths: { value: [], default: [], name: i18n.t('summary.roof_bridge_lengths') },
      roofBridgeShortLengths: { value: [], default: [], name: i18n.t('summary.roof_bridge_short_lengths') },
      roofRailLengths: { value: [], default: [], name: i18n.t('summary.roof_rail_lengths') },
      roofHatchSafetyAmount: { value: 0, default: 0, name: i18n.t('summary.roof_hatch_safety_amount') },

      accessoryColor: { value: null, default: null, name: i18n.t('summary.accessory_color') },
      roofTilt: { value: null, default: null, name: i18n.t('summary.roof_tilt') },
      roofLength: { value: null, default: null, name: i18n.t('summary.roof_length') },
      snowZone: { value: null, default: null, name: i18n.t('summary.snow_zone') },
    },

    frameData: {
      loading: true,
    },
  },
  getters: {
    getSteps(state) {
      // return state.steps
      return state.steps;
    },
    getActiveStep(state) {
      return state.activeStep;
    },
    getValidSteps(state) {
      return state.validSteps;
    },
    getRoofTypes(state) {
      return state.products.reduce((subset, productFamily) => {
        if (productFamily.type === 'roofType') {
          let defaultVariant = productFamily.variants.find((variant) => variant.color == 'Tegelröd');
          productFamily.variants.forEach((variant) => {
            // subset.push({...productFamily, ...variant})
            subset.push({
              id: variant.id,
              name: variant.name,
              subheading1: productFamily.subtext,
              subheading2: productFamily.subtext2,
              imageUrl: productFamily.imageUrl,
              artNumber: variant.artNumber,
            });
          });
        }
        return subset;
      }, []);
    },
    getRooftiles(state) {
      return state.products.reduce((subset, productFamily) => {
        if (productFamily.type === 'rooftile') {
          let defaultVariant = productFamily.variants.find((variant) => variant.color == 'Tegelröd');
          subset.push({
            id: productFamily.name,
            name: productFamily.name,
            subheading1: productFamily.subtext,
            subheading2: productFamily.subtext2,
            imageUrl: productFamily.imageUrl,
            artNumber: defaultVariant ? defaultVariant.artNumber : productFamily.variants[0].artNumber,
          });
        }
        return subset;
      }, []);
    },
    getAvailableTreatments(state) {
      let SelectedProductFamily = state.products.filter((productFamily) => productFamily.name === state.selections.rooftile.value);
      SelectedProductFamily = SelectedProductFamily[0];
      let uniqe = {};
      let treatments = [];

      if (!SelectedProductFamily) return [];

      SelectedProductFamily.variants.forEach((variant) => {
        if (!uniqe[variant.treatment]) {
          let defaultVariant = SelectedProductFamily.variants.find((e) => e.treatment == variant.treatment && e.color == 'Tegelröd');
          treatments.push({
            id: variant.treatment,
            name: variant.treatment,
            subheading1: variant.subtext,
            subheading2: variant.subtext2,
            imageUrl: SelectedProductFamily.imageUrl,
            artNumber: defaultVariant ? defaultVariant.artNumber : variant.artNumber,
          });
          uniqe[variant.treatment] = true;
        }
      });
      return treatments;
    },
    getAvailableColors(state) {
      let SelectedProductFamily = state.products.filter((productFamily) => productFamily.name === state.selections.rooftile.value);
      SelectedProductFamily = SelectedProductFamily[0];
      let uniqe = {};
      let colors = [];

      SelectedProductFamily.variants.forEach((variant) => {
        if (!uniqe[variant.color] && variant.treatment === state.selections.rooftileTreatment.value) {
          colors.push({
            id: variant.color,
            name: variant.color,
            subheading1: variant.subtext,
            subheading2: variant.subtext2,
            imageUrl: SelectedProductFamily.imageUrl,
            artNumber: variant.artNumber,
          });
          uniqe[variant.color] = true;
        }
      });
      return colors;
    },
    getSnowSafetyProducts(state) {
      let snowSafety = state.products.filter((productFamily) => productFamily.type === 'snowSafety');
      snowSafety = snowSafety[0];
      let uniqe = {};
      let products = [];

      snowSafety.variants.forEach((variant) => {
        if (!uniqe[variant.artNumber]) {
          products.push({
            id: variant.color,
            name: `${snowSafety.name} - ${variant.color}`,
            subheading1: variant.subtext,
            subheading2: variant.subtext2,
            imageUrl: variant.imageUrl,
          });
          uniqe[variant.color] = true;
        }
      });
      return products;
    },
    getProducts(state) {
      return state.products;
    },
    getSelections(state) {
      return state.selections;
    },
    getSelectedValue: (state) => (valueName) => {
      return state.selections[valueName].value;
    },
    getFieldValidationErrors(state) {
      return state.fieldValidationErrors;
    },
    isStepComplete: (state) => (criterias, single = false, evalErrors) => {
      let approvedCriterias = [];
      if (evalErrors) {
        this.updateSelectionStatus(false);
        return false;
      }
      if (criterias === undefined || criterias.length === 0) {
        this.updateSelectionStatus(true);
        return true;
      }

      criterias.forEach((condition) => {
        let selectedValue = this.$store.getters.getSelectedValue(condition);
        if (selectedValue === null) return;

        if (['string', 'number', 'boolean'].includes(typeof selectedValue)) {
          if (selectedValue !== '0' && selectedValue !== 0) approvedCriterias.push(condition);
        } else if (selectedValue.length > 0 && selectedValue !== '0') {
          approvedCriterias.push(condition);
        }
      });
      if (single && approvedCriterias.length === 0) {
        this.updateSelectionStatus(false);
        return false;
      } else if (!single && approvedCriterias.length < criterias.length) {
        this.updateSelectionStatus(false);
        return false;
      } else {
        this.updateSelectionStatus(true);
        return true;
      }
    },
    locale() {
      return i18n.locale || 'sv-SE';
    },
  },
  mutations: {
    setSteps(state, payload) {
      state.steps = payload;
    },
    setActiveStep(state, payload) {
      state.activeStep = payload;
    },
    setSliderValue(state, payload) {
      state[payload.name] = payload.value;
    },
    setValue(state, payload) {
      let selections = { ...state.selections };
      selections[payload.name].value = payload.value;

      Vue.set(state, 'selections', selections);
      // state.selections[payload.name].value = payload.value;
    },
    resetValue(state, payload) {
      Vue.set(state.selections[payload], 'value', state.selections[payload].default);
    },
    setValidSteps(state, payload) {
      state.validSteps = payload;
    },
    setFieldValidationErrors(state, payload) {
      state.fieldValidationErrors = payload;
    },
    setFrameData(state, data) {
      state.frameData = data;
    },
    updateLanguage(state, language) {
      localStorage.setItem('language', language);
      i18n.locale = language;
      // // reload page, because values are tied to the language...
      // window.location.reload();
    },
  },
  actions: {
    setSteps({ state, commit, dispatch, rootState }, payload) {
      commit('setSteps', payload);
    },
    previousStep({ state, commit, dispatch, rootState }) {
      commit('setActiveStep', state.activeStep - 1);
    },
    nextStep({ state, commit, dispatch, rootState }) {
      commit('setActiveStep', state.activeStep + 1);
    },
    goToStep({ state, commit, dispatch, rootState }, step) {
      commit('setActiveStep', step);
    },
    updateValidSteps({ commit }, payload) {
      commit('setValidSteps', payload);
    },
    updateValue({ commit }, payload) {
      commit('setValue', payload);
    },
    updateFieldValidationErrors({ commit }, payload) {
      commit('setFieldValidationErrors', payload);
    },
    updateRequierdFields({ state, commit }, { step: stepName, value }) {
      let steps = JSON.parse(JSON.stringify(state.steps));
      let index = steps.findIndex((step) => step.id === stepName);
      steps[index].requierdFileds = value;
      commit('setSteps', steps);
    },
    resetValue({ commit }, payload) {
      commit('resetValue', payload);
    },
    updateFrameData(context, data) {
      context.commit('setFrameData', data);
    },
    setLanguage({ commit }, language) {
      commit('updateLanguage', language);
    },
  },
});
export default store;
