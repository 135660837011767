var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hero is-fullheight", attrs: { id: "app" } },
    [
      _c("breadcrumbs", { attrs: { activeStep: _vm.activeStep } }),
      _c("div", { key: _vm.locale, staticClass: "hero-body" }, [
        _c("div", { staticClass: "container has-text-centered" }, [
          _c("div", { staticClass: "columns is-centered" }, [
            _c(
              "div",
              { staticClass: "column is-7" },
              [_c(_vm.steps[_vm.activeStep].id, { tag: "component" })],
              1
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }