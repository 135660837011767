<template>
  <div id="app" class="hero is-fullheight">
    <!-- <LanguageSelector class="centered mb-2" /> -->
    <breadcrumbs
      :activeStep="activeStep"
    />
    <div :key="locale" class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered">
          <div class="column is-7">
            <component :is="steps[activeStep].id"></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LanguageSelector from '@/components/LanguageSelector'
import { mapGetters } from 'vuex'

export default {
  name: 'app',
  data: function () {
    return {
      baseSteps: [
        {
          id: 'chooseRoof',
          name: this.$t('roof.pick_rooftype'),
          stepName: `1. ${this.$t('step.roof_type')}`,
          requierdFileds: ['rooftype'],
        },
        {
          id: 'chooseRoofTile',
          name: this.$t('roof.pick_rooftile'),
          stepName: `2. ${this.$t('step.roof_tile')}`,
          requierdFileds: ['rooftile', 'rooftileColor', 'rooftileTreatment']
        },
        {
          id: 'safeProducts',
          name: this.$t('roof.roof_security'),
          stepName: `3. ${this.$t('step.roof_security')}`,
          requierdFileds: ['roofSafetyLengths', 'roofBridgeLengths', 'roofRailLengths', 'roofHatchSafetyAmount'],
          requiredType: 'single'
        },
        {
          id: 'alternatives',
          name: this.$t('roof.alternatives'),
          stepName: `4. ${this.$t('step.alternatives')}`,
          requierdFileds: ['accessoryColor']
        },
        {
          id: 'summaryStep',
          name: this.$t('roof.summary'),
          stepName: this.$t('roof.summary'),
          requierdFileds: []
        },
      ]
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      const data = event.data
      if (typeof data !== 'object' || data === null) {
        return;
      }
      switch (data.type) {
        case 'bendersReady':
          window.parent.postMessage({type: 'bendersInit'}, '*');
          break;
        case 'bendersInit':
          this.$store.dispatch('updateFrameData', data.data);
          break;
        case 'bendersLoading':
          this.$store.dispatch('updateFrameData', {
            ...this.$store.state.frameData,
            loading: data.data
          });
          break;
      }
    });
  },
  computed: {
    ...mapGetters(['locale']),
    steps () {
      return this.$store.getters.getSteps
    },
    activeStep () {
      return this.$store.getters.getActiveStep
    },
    fieldValidationErrors () {
      return this.$store.getters.getFieldValidationErrors
    }
  },
  methods: {},
  created() {
    this.$store.dispatch('setSteps', this.baseSteps)
  },
  components: {
    breadcrumbs: () => import("@/components/breadcrumbs navlist"),
    // stepNav: () => import("@/components/stepNav2"),
    chooseRoof: () => import("@/components/steps/chooseRoof"),
    alternatives: () => import("@/components/steps/alternatives"),
    chooseRoofTile: () => import("@/components/steps/chooseRoofTile"),
    safeProducts: () => import("@/components/steps/safeProducts"),
    summaryStep: () => import("@/components/steps/summaryStep"),
    // LanguageSelector
  }
}
</script>

<style lang="sass">
@import "@/assets/styles/style.sass"

.button.button
  height: auto
  border-radius: $control-radius
  line-height: 1
.input, .textarea, .select select
  border-width: 2px
  line-height: 1
  border-radius: $control-radius

.table
  td,th
    vertical-align: middle

.title
  margin-bottom: 1rem
  @media (min-width: 767px)
    margin-bottom: 1rem
  /*&::after
    content: ''
    display: block
    width: 0
    height: 0
    border-left: 8px solid transparent
    border-right: 8px solid transparent
    border-top: 10px solid $red
    margin: 10px auto 0 auto
    */

.option-list.option-list
  margin-bottom: 1rem
  @media (min-width: 767px)
    margin-bottom: 4rem

.bg-svg
  position: fixed
  top: 0
  left: 0
  z-index: -1
  width: 100vw
  height: 100vh

.vue-slider-rail
  background: $grey-light
  .vue-slider-process
    background: $grey-light
  .vue-slider-dot-handle
    box-shadow: none
    background: $red
    border-radius: 0
    &:hover
      cursor: pointer
  .vue-slider-dot-tooltip-top
    top: 0
  .vue-slider-dot-tooltip-inner
    border-color: transparent
    background: transparent
    border-radius: 0
    color: $dark
  &:hover
    cursor: pointer
.range-field
  label
    min-width: 13%
    text-align: left

// BK-43
.hero.is-fullheight
  min-height: unset !important
.hero-body
  align-items: start !important
  flex-grow: unset !important

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)
    .hero-body
        display: block !important

.button-left,
.button-right
    color: #a6192e
    font-weight: bold
    position: relative

.button-left
    padding-right: 0

.button-right
    padding-left: 0

.button-left::after,
.button-right::after
    content: ""
    display: block
    width: 0
    height: 0
    position: absolute

.button-left::after
    border-top: 8px solid transparent
    border-bottom: 8px solid transparent
    border-right: 10px solid #a6192e
    left: 0

.button-right::after
    border-bottom: 8px solid transparent
    border-top: 8px solid transparent
    border-left: 10px solid #a6192e
    right: 0
</style>
