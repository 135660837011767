const neededProducts = {
  state: () => ({ 
    neededProducts: {

    } 
  }),
  mutations: {  },
  actions: {  },
  getters: {  }
}
export default neededProducts

